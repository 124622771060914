/* eslint camelcase: "off" */
/* globals window, $ */

'use strict';

Number.isInteger =
  Number.isInteger ||
  function (value) {
    return (
      typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, n));

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ','),
  );
};

$.extend($.easing, {
  easeInOutQuint: function (x, t, b, c, d) {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t * t * t * t + b;
    }
    return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
  },
});

$(function () {
  var timer = 0;
  // Default time delay before checking location
  var callBackTime = 50;

  // On scroll
  $(window).on('scroll', function () {
    if (timer) {
      clearTimeout(timer);
    }
    // Use a buffer so we don't call trackLocation too often.
    timer = setTimeout(trackLocation, callBackTime);
  });

  // Scroll to div
  $('a[href*="#"]:not([href="#"])').click(function () {
    if (
      location.pathname.replace(/^\//, '') ===
        this.pathname.replace(/^\//, '') &&
      location.hostname === this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate(
          {
            scrollTop: target.offset().top,
          },
          800,
        );
        return false;
      }
    }
  });

  function trackLocation() {
    //This empty function prevents the nav bar from glitching.. ?! (Sorry)
  }

  var video = $('#device-video');
  var videoLoaded = false;
  video.bind('timeupdate pagehide', function () {
    if (!videoLoaded) {
      videoLoaded = true;
      video.addClass('loaded');
      $('#video-background').addClass('loaded');
    }
  });

  runWhenVideoLoaded($('#video-background'), function (video) {
    video[0].pause();
  });

  $(window).one('beforeunload', function () {
    $('video').hide();
  });

  // Portfolio calculator
  let pc = $('.portfolio-calculator');
  let wm = $('.portfolio-calculator__output__wealth-wrapper');
  const pcMin = 0;
  const pcMax = 10000;
  let pcInitialText = pc.find(
    '.portfolio-calculator__inputs__input--initial .portfolio-calculator__inputs__input__sum__value',
  );
  let pcInitialVal = pc.find(
    '.portfolio-calculator__inputs__input--initial input[type=range]',
  );
  let pcMonthlyText = pc.find(
    '.portfolio-calculator__inputs__input--monthly .portfolio-calculator__inputs__input__sum__value',
  );
  let pcMonthlyVal = pc.find(
    '.portfolio-calculator__inputs__input--monthly input[type=range]',
  );
  let pcResultText = pc.find('.portfolio-calculator__output__sum__value');
  pcInitialVal.attr('min', pcMin);
  pcInitialVal.attr('max', pcMax);
  pcMonthlyVal.attr('min', pcMin);
  pcMonthlyVal.attr('max', pcMax);

  let yearsIndex = 2;
  const yearsLabel = $('.portfolio-calculator__output__years');

  let previousValue = 0;

  let pcOnChange = function (animateValueChange) {
    const inp = pcInitialVal.val() / (pcMax - pcMin);
    const initialInvestment =
      inp <= 0.03
        ? Math.round(((inp / 0.3) * 100000) / 1000) * 1000
        : inp <= 0.3
          ? Math.round(((inp / 0.3) * 100000) / 1000) * 1000
          : inp < 0.95
            ? 100000 +
              Math.round((((inp - 0.3) / 0.65) * 4900000) / 10000) * 10000
            : 5000000;
    const mp = pcMonthlyVal.val() / (pcMax - pcMin);
    const monthlyInvestment = Math.round((mp * 20000) / 100) * 100;

    pcInitialText.text(initialInvestment.format(0, 3, '  ', '.'));
    pcMonthlyText.text(monthlyInvestment.format(0, 3, '  ', '.'));

    pcInitialVal.attr('style', '--progress: ' + inp * 100 + '%');
    pcMonthlyVal.attr('style', '--progress: ' + mp * 100 + '%');

    let years;

    switch (yearsIndex) {
      case 0: {
        years = 5;
        break;
      }
      case 1: {
        years = 10;
        break;
      }
      case 2: {
        years = 15;
        break;
      }
      case 3: {
        years = 30;
        break;
      }
    }

    yearsLabel.html(`${years}&nbsp;år`);

    const estimatedValueAfterNYears =
      // `returnEstimates` is injected in `index.hbs`
      // eslint-disable-next-line no-undef
      returnEstimates[`${years}-years`]['median'];
    const months = years * 12;
    const returnPerMonth =
      Math.pow(1 + estimatedValueAfterNYears, 1 / months) - 1;

    const resultValue = valueAfterPeriods(
      initialInvestment,
      monthlyInvestment,
      returnPerMonth,
      months,
    );

    const formatValue = (resultValue) => {
      const roundToValue =
        resultValue >= 100000 ? 10000 : resultValue >= 25000 ? 1000 : 100;
      pcResultText.text(
        (Math.round(resultValue / roundToValue) * roundToValue).format(
          0,
          3,
          '  ',
          '.',
        ),
      );
    };

    if (animateValueChange === true) {
      $({ val: previousValue }).animate(
        { val: resultValue },
        {
          duration: 250,
          easing: 'linear',
          step: (value) => formatValue(value),
          complete: () => {
            previousValue = resultValue;
          },
        },
      );
    } else {
      previousValue = resultValue;
      formatValue(resultValue);
    }

    if (initialInvestment >= 5000000) {
      wm.addClass('active');
    } else {
      wm.removeClass('active');
    }
  };
  pc.on('input change', pcOnChange);

  // Show or hide portfolio calculator year picker
  $('.portfolio-calculator__output__label').click(() => {
    const dropdownContent = $('.portfolio-calculator__output__dropdown');
    dropdownContent.toggleClass('show');
  });

  // Pick number of years to show
  $('.portfolio-calculator__output__dropdown a').click((evt) => {
    let index = Array.from(evt.target.parentElement.children).indexOf(
      evt.target,
    );

    yearsIndex = index;
    pcOnChange(true);
  });

  // Animate the slider when it scrolls into view
  $(window).bind('scroll.portfolio-calculator', function () {
    let rect = pc[0].getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let visible = elemTop >= 0 && elemBottom <= window.innerHeight;
    if (visible) {
      $(window).unbind('.portfolio-calculator');
      pcInitialVal.val(0).change();
      pcInitialVal.animate(
        {
          value: pcMin + (pcMax - pcMin) * 0.3,
        },
        {
          duration: 1400,
          easing: 'easeInOutQuint',
          step: function () {
            pcInitialVal.change();
          },
        },
      );
      pcMonthlyVal.val(0).change();
      pcMonthlyVal.animate(
        {
          value: Math.round(pcMin + (pcMax - pcMin) * (3000 / 20000)),
        },
        {
          duration: 1400,
          easing: 'easeInOutQuint',
          step: function () {
            pcMonthlyVal.change();
          },
        },
      );
    }
  });
});

function runWhenVideoLoaded(video, f) {
  video.one('timeupdate', function () {
    f(video);
  });
}

$(document).ready(function () {
  // Get Google AdWords script
  var pclass = urlParam('pclass');
  $('.pricing').addClass(pclass);
  $.getScript('//www.googleadservices.com/pagead/conversion_async.js');
});

function urlParam(name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
    window.location.href,
  );
  if (results === null) {
    return null;
  } else {
    return decodeURI(results[1]) || 0;
  }
}

function valueAfterPeriods(
  initialInvestment,
  investmentPerPeriod,
  interestRatePerPeriod,
  numberOfPeriods,
) {
  let value = initialInvestment;

  for (let index = 0; index < numberOfPeriods; index++) {
    value += value * interestRatePerPeriod + investmentPerPeriod;
  }

  return value;
}
